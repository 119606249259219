import { FormEvent } from 'react';
import { CPF_MAX_LENGTH, rules } from '@components/input-cpf/validation';
import Form from '@styles/form';
import { CPFMask } from '@shared/helpers';

export default function InputCpf({
  errors,
  register,
  required,
  value,
  setValue,
  handleChange,
  name = 'cpf',
  disabled = false,
  readOnly = false,
  onBlur = null,
}) {
  const handleValue = (event: FormEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    event.currentTarget.value = `${CPFMask(event.currentTarget.value)}`;
    handleChange(event.currentTarget.value);
    if (event.currentTarget.value.length === CPF_MAX_LENGTH) {
      setValue('cpf', event.currentTarget.value, { shouldValidate: true });
    }
  };

  return (
    <Form.Field>
      <Form.Label disabled={disabled} readOnly={readOnly} htmlFor="cpf">
        CPF
      </Form.Label>
      <Form.Input
        name={name}
        error={errors.cpf}
        ref={register({ required, ...rules })}
        type="tel"
        placeholder="000.000.000-00"
        autoComplete="off"
        id={name}
        defaultValue={value}
        onChange={handleValue}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
      />
      {errors.cpf && <Form.Feedback>{errors.cpf.message}</Form.Feedback>}
    </Form.Field>
  );
}
