import styled from 'styled-components';

import { StylePropsType } from '@components/input-password/types';
import Form from '@styles/form';
import Link from 'next/link';
import { defaultTheme } from '@styles/theme';

export const getColorPasswordStrength = (type: string) => {
  switch (type) {
    case 'differ':
      return defaultTheme.tokens.red[550];
    case 'weak':
      return defaultTheme.tokens.red[550];
    case 'moderate':
      return defaultTheme.tokens.orange[200];
    case 'strong':
      return defaultTheme.tokens.green[500];
    case 'equal':
      return defaultTheme.tokens.green[500];
    default:
      return 'transparent';
  }
};

export const getWidthPasswordStrength = (type: string) => {
  switch (type) {
    case 'weak':
      return '33%';
    case 'moderate':
      return '66%';
    default:
      return '100%';
  }
};

export default {
  PasswordStrength: styled.div`
    border-bottom: 2px solid ${({ type }: StylePropsType) => getColorPasswordStrength(type)};
    width: ${({ type }: StylePropsType) => getWidthPasswordStrength(type)};
    top: -1px;
    position: inherit;
  `,
  ForceLevelLabel: styled(Form.Tip)`
    color: ${({ type }: StylePropsType) => getColorPasswordStrength(type)};
    font-size: 11px;
    line-height: 16px;
  `,
  Tip: styled(Form.Tip)`
    font-size: 11px;
    bottom: -28px;
  `,
  Feedback: styled(Form.Feedback)`
    bottom: -38px;
  `,
  ForgotPassContainer: styled(Link)`
    text-decoration: none;
  `,
};
