import Form from '@styles/form';
import { useEffect } from 'react';

export default function Recaptcha({
  onChange = null,
  onExpired = null,
  action = '',
  onError = null,
}: {
  onChange?: (token: string) => void;
  onExpired?: () => void;
  onError?: () => void;
  action?: string;
}) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // @ts-ignore
      grecaptcha?.enterprise.ready(() => {
        try {
          // @ts-ignore
          grecaptcha?.enterprise?.render('recaptcha', {
            sitekey: process.env.NEXT_PUBLIC_CAPTCHA_KEY,
            callback: (token: string) => onChange(token),
            'expired-callback': onExpired,
            'error-callback': onError,
            badge: 'inline',
            action,
          });
          // eslint-disable-next-line no-empty
        } catch (_) {}
      });
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Form.RecaptchaContainer data-testid="recaptcha">
      <div id="recaptcha" />
    </Form.RecaptchaContainer>
  );
}
