import { ChangeEvent, useEffect, useRef, useState } from 'react';

import Styles from '@components/input-password/styles';
import { PropsType } from '@components/input-password/types';
import * as Validation from '@components/input-password/validation';
import Tooltip from '@components/tooltip';
import Form from '@styles/form';

export default function InputPassword(props: PropsType) {
  const {
    errors,
    field,
    applyRules,
    value,
    isConfirm,
    labelField,
    setValue,
    placeholder,
    register,
    tooltip,
    errorMessage,
    hideError = false,
    onBlur,
    handleChange,
  } = props;
  const errorField = errors[field];
  const registerRules = applyRules ? Validation.rules : { required: 'Campo obrigatório' };
  const [inputType, setInputType] = useState('password');
  const [passwordStrengthType, setPasswordStrengthType] = useState('');

  const fieldRef = useRef(null);

  useEffect(() => {
    const passwordRef = fieldRef.current.children[1];
    passwordRef.value = value;
  }, [value]);

  const handleValue = () => {
    const passwordRef = fieldRef.current.children[1];
    handleChange(passwordRef.value);
  };

  const handleEye = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const renderPasswordStrength = () => {
    const valueCheck = !!value && field === 'confirmationPasswordChange';

    if (passwordStrengthType && !isConfirm) {
      let passwordStrengthLabel;
      switch (passwordStrengthType) {
        case 'weak':
          passwordStrengthLabel = 'Fraca';
          break;
        case 'moderate':
          passwordStrengthLabel = 'Moderada';
          break;
        case 'strong':
          passwordStrengthLabel = 'Forte';
          break;
        default:
          break;
      }
      return (
        <Styles.PasswordStrength type={passwordStrengthType}>
          <Styles.ForceLevelLabel type={passwordStrengthType}>{passwordStrengthLabel}</Styles.ForceLevelLabel>
        </Styles.PasswordStrength>
      );
    }

    if (!isConfirm && valueCheck) {
      return (
        <Styles.PasswordStrength type="differ">
          <Styles.ForceLevelLabel type="differ">Senhas não correspondem, tente novamente</Styles.ForceLevelLabel>
        </Styles.PasswordStrength>
      );
    }
    if (isConfirm && valueCheck) {
      return (
        <Styles.PasswordStrength type="equal">
          <Styles.ForceLevelLabel type="equal">Senhas conferem</Styles.ForceLevelLabel>
        </Styles.PasswordStrength>
      );
    }

    return null;
  };

  const renderFeedbackError = () => {
    if (errorField && !hideError) {
      return isConfirm ? (
        <Form.Feedback>{errorField?.message}</Form.Feedback>
      ) : (
        <Styles.Feedback>{errorMessage || errorField?.message}</Styles.Feedback>
      );
    }
    return null;
  };

  const applyPasswordRules = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordStrengthType(Validation.getPasswordStrengthType(e.target.value));
    setValue && setValue(field, e.target.value);

    handleValue();
  };

  return (
    <Form.Field ref={fieldRef}>
      <Form.Label htmlFor={field}>{labelField}</Form.Label>
      <Form.Input
        name={field}
        id={field}
        error={errorField}
        ref={register(registerRules)}
        type={inputType}
        placeholder={placeholder}
        autoComplete="off"
        onChange={applyRules ? applyPasswordRules : undefined}
        onBlur={onBlur}
        data-testid="inputPassword"
        hideError={hideError}
      />
      {renderPasswordStrength()}
      {renderFeedbackError()}
      <Form.Eye display={inputType} onClick={handleEye} data-testid="eye" />
      {tooltip && (
        <Tooltip
          message="A senha faz parte da sua credencial de acesso ao Whitebook e não deve ser compartilhada para garantir a segurança do seu perfil no serviço."
          align="right-short"
        />
      )}
    </Form.Field>
  );
}
